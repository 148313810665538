<template>
	<div>
		<!-- 设备查询参数 -->
		<div class="body nav-top">
			<Form ref="formInline" :label-width="45" :model="search">
				<FormItem label="搜索" style="width: 25%">
					<Row :gutter="10">
						<Col :span="22">
						<Input type="text" v-model="search.device_id" placeholder="设备id(Sn号)" />
						</Col>
						<Col :span="2">
						<Button type="primary" @click="init()">搜索</Button>
						</Col>
					</Row>
				</FormItem>
				<FormItem label="状态" class="bottom-form-item">
					<RadioGroup v-model="search.check_bound" button-style="solid" type="button" @on-change="init">
						<Radio :label="2">全部</Radio>
						<Radio :label="0">未绑定</Radio>
						<Radio :label="1">已绑定</Radio>
					</RadioGroup>
				</FormItem>
			</Form>
		</div>
		<!-- 所有设备显示 -->
		<div class="body">
			<Table :columns="columns" :data="data" :loading="loading">
				<template slot-scope="{row}" v-if="row.is_bound " slot="operate">
					<Button @click="get_record(row.device_id)">查看设备血压记录</Button>
				</template>
			</Table>
			<div class="page-ser">
				<Page class-name="r-page" :total="total" :page-size="search.page_size" :current="search.current"
					show-total show-sizer :page-size-opts="[15,30,50]" />
			</div>
		</div>
		<!-- 血压记录弹框 -->
		<Modal v-model="record" title="血压记录" :width="800">
			<div class="search-date">
				<DatePicker type="daterange" placement="bottom-end" placeholder="选择时间区域"
					style="width: 300px; float: right;"></DatePicker>
			</div>
			<Table style="clear: both;" :columns="col_record" :data="data_record"></Table>
			<div class="page-ser">
				<Page class-name="r-page" :total="record_search.total" :page-size="record_search.page_size"
					:current="record_search.current" :page-size-opts="[15,30,50]" show-total show-sizer></Page>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		name: "Xueyaji",
		data() {
			return {
				loading: false,
				record: false,
				total: 0,
				search: {
					device_id: '',
					bound_user_name: '',
					check_bound: 2,
					page_size: 15,
					current: 1
				},
				record_search: {
					device_id: null,
					total: 0,
					page_size: 15,
					current: 1
				},
				columns: [{
						title: '设备id',
						key: 'device_id',
						minWidth: 80,
						align: 'center'
					},
					{
						title: '绑定的用户名称',
						key: 'bound_user_name',
						minWidth: 100,
						align: 'center',
						render: (h, params) => {
							return h('div', [
								h('span', {}, params.row.is_bound ? params.row.bound_user_name : '未绑定')
							])
						}
					},
					{
						title: '绑定状态',
						key: 'is_bound',
						minWidth: 80,
						align: 'center',
						render: (h, params) => {
							return h('div', [
								h('Tag', {
									props: {
										color: params.row.is_bound ? 'success' : '#999999'
									}
								}, params.row.is_bound ? '已绑定' : '未绑定')
							])
						}
					},
					{
						title: '绑定时间',
						key: 'create_time',
						minWidth: 140,
						align: 'center'
					},
					{
						title: '操作',
						align: 'center',
						fixed: 'right',
						slot: 'operate',
						width: 220,
						minWidth: 220
					}
				],
				data: [],
				col_record: [{
						title: '测量时间',
						key: 'date_time',
						minWidth: 150,
						width: 200,
						align: 'center'
					},
					{
						title: '收缩压',
						key: 'shrink_stress',
						minWidth: 120,
						width: 120,
						align: 'center'
					},
					{
						title: '舒张压',
						key: 'diastole_stress',
						minWidth: 120,
						width: 120,
						align: 'center'
					},
					{
						title: '脉搏跳动次数',
						key: 'pulse_number',
						minWidth: 130,
						width: 130,
						align: 'center'
					},
					{
						title: '用户id',
						key: 'user_name',
						minWidth: 100,
						align: 'center'
					}
				],
				data_record: []
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				var that = this
				this.loading = true
				this.requestApi('/adm/blood_pressure_index', this.search).then((res) => {
					that.data = res.data.data
					that.total = res.data.total
					that.search.page_size = res.data.per_page
					that.search.current = res.data.current_page

					that.loading = false
				})
			},
			get_record(id) {
				this.record = true
				this.record_search.device_id = id
				var _this = this
				// this.loading = true
				this.requestApi('/adm/record_glucose_index', this.record_search).then((res) => {
					_this.data_record = res.data.data
					// _this.loading = false
				})
			}
		}
	}
</script>

<style scoped>
	.body {
		background-color: #fff;
	}

	.nav-top {
		margin-bottom: 15px;
		padding: 15px;
	}

	.bottom-form-item {
		margin-bottom: 0px
	}

	.page-ser {
		padding: 12px;
	}

	.page-ser .r-page {
		text-align: right;
	}

	.search-date {
		margin-bottom: 50px;
		margin-right: 5px;
	}
</style>
